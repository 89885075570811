<template>
  <div id="thank-you-page">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1 v-show="OnlineOnly" class="align-center cl-main m10 mt40">
            {{ ((order_increment_id && payment_result == 'canceled') || status == 'canceled') ? $t('Your order is pending payment') : $t('Thank you for purchase!') }}
          </h1>
          <p
            v-show="OnlineOnly && order_increment_id && payment_result == 'completed'"
            class="m20 p0 align-center weight-700"
          >
            {{ $t('Your Order number: #{id}', {id: orderIncrementId}) }}
          </p>
          <p class="mobile-p10 mb40 align-center small-font" v-show="OnlineOnly && order_increment_id && payment_result == 'completed'">
            {{ $t('You have successfuly placed the order. You can check status of your order by using our ') }}
            <b>
              <router-link v-show="isLogged" class="pointer" :to="localizedRoute('/my-account')" exact>
                {{ $t('delivery status') }}
              </router-link>
            </b>
            <b v-show="!isLogged" @click="goToAccount()" class="pointer">{{ $t('delivery status') }}</b>
            {{ $t(' feature. You will receive an order confirmation e-mail with details of your order and a link to track its progress.') }}
          </p>
          <p class="mobile-p10" v-show="OnlineOnly && ((order_increment_id && payment_result == 'canceled') || status == 'canceled')">
            {{ $t('Unfortunately your order has not been placed correctly. This may be due to a technical issue with the payment, insufficient funds in your account or a slow payment gateway response.') }}
          </p>
          <h4 v-show="OfflineOnly">
            {{ $t('You are offline') }}
          </h4>
          <p class="mobile-p10" v-show="OfflineOnly && !isNotificationSupported">
            {{ $t('To finish the order just come back to our store while online. Your order will be sent to the server as soon as you come back here while online and then confirmed regarding the stock quantities of selected items') }}
          </p>
          <p class="mobile-p10" v-show="OfflineOnly && isNotificationSupported && !isPermissionGranted">
            {{ $t("You can allow us to remind you about the order via push notification after coming back online. You'll only need to click on it to confirm.") }}
          </p>
          <p class="mobile-p10" v-show="OfflineOnly && isNotificationSupported && isPermissionGranted">
            <strong>{{ $t('You will receive Push notification after coming back online. You can confirm the order by clicking on it') }}</strong>
          </p>
          <!-- <p v-if="!isPermissionGranted && isNotificationSupported">
            <button-outline color="dark" @click.native="requestNotificationPermission()" >
              {{ $t('Allow notification about the order') }}
            </button-outline>
          </p> -->
          <div class="col-md-12 row space-between p0 m0">
            <div class="typ-summary col-lg-6 col-xs-12 p0 m0">
              <div class="data-wrapper col-xs-12 p0 m0">
                <div class="col-md-6 col-xs-12 p0 customer-data p0">
                  <h3 class="m0 py15 px5"
                      v-if="orderDetails && orderDetails.payment"
                  >
                    {{ $t('Customer data') }}:
                  </h3>
                  <div class="py10 data-container" v-if="orderDetails && orderDetails.payment">
                    <p class="m0 px10">
                      {{ orderDetails.payment.firstName }} {{ orderDetails.payment.lastName }}
                    </p>
                    <p class="m0 px10">
                      {{ orderDetails.payment.streetAddress }} {{ orderDetails.payment.apartmentNumber ? `/${orderDetails.payment.apartmentNumber}` : '' }}<br>
                      {{ orderDetails.payment.zipCode }} {{ orderDetails.payment.city }}
                    </p>
                    <p class="m0 px10">
                      {{ orderDetails.payment.type && !orderDetails.payment.type.includes('Osoba prywatna') ? 'Faktura' : 'Paragon' }}
                    </p>
                    <template v-if="orderDetails.payment.type && !orderDetails.payment.type.includes('Osoba prywatna')">
                      <p class="m0 px10">
                        {{ orderDetails.payment.company && orderDetails.payment.company }}
                      </p>
                      <p class="m0 px10" v-show="orderDetails.payment.taxId">
                        {{ $t('Tax number') }}: {{ orderDetails.payment.taxId }}
                      </p>
                    </template>
                    <p class="m0 px10">
                      {{ orderDetails.payment.emailAddress }}
                    </p>
                    <p class="m0 px10">
                      {{ $t('Phone.') }} {{ orderDetails.payment.phoneNumber }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6 col-xs-12 p0 customer-data">
                  <h3 class="m0 py15 px5 shipping-data"
                      v-if="orderDetails && orderDetails.shipping"
                  >
                    {{ $t('Shipping data') }}:
                  </h3>
                  <div class="py10 data-container" v-if="orderDetails && orderDetails.shipping">
                    <p class="m0 px10">
                      {{ orderDetails.shipping.firstName }} {{ orderDetails.shipping.lastName }}
                    </p>
                    <p class="m0 px10">
                      {{ orderDetails.shipping.company && orderDetails.shipping.company }}
                    </p>
                    <p class="m0 px10">
                      {{ orderDetails.shipping.streetAddress }} {{ orderDetails.shipping.apartmentNumber }} {{ orderDetails.shipping.flatNumber ? `/${orderDetails.shipping.flatNumber}` : '' }}<br>
                      {{ orderDetails.shipping.zipCode }} {{ orderDetails.shipping.city }}
                    </p>
                    <p class="m0 px10" v-show="orderDetails.shipping.phoneNumber">
                      {{ $t('Phone.') }} {{ orderDetails.shipping.phoneNumber }}
                    </p>
                    <template v-for="(total, index) in cardTotals">
                      <p class="m0 px10" v-show="total.code === 'shipping'" :key="index">
                        {{ total.title }}
                      </p>
                      <p class="m0 px10" v-show="total.code === 'grand_total'" :key="10 + index">
                        {{ $t('Order Value') }} {{ total.value | price }}
                      </p>
                    </template>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-lg-12 m0 p0">
                <h3 class="m0 py15 px5 order" v-show="cardProducts.length">
                  {{ $t('Your order') }}:
                </h3>
                <div v-for="prod in cardProducts" class="card-product p10 row start-xs brdr-top-1 brdr-cl-secondary" :key="prod.sku">
                  <div class="card-product-image col-xs-3 col-md-2">
                    <product-image :image="prod.image" class="checkout-product__image" />
                  </div>
                  <div class="card-product-name flex column center-xs col-xs-9 col-sm-10 align-left">
                    <span class="cl-light-gray weight-600">
                      {{ prod.name }}<br>
                    </span>
                    <div v-for="opt in prod.totals.options" :key="opt.label" class="cl-light-gray">
                      <span class="opn ">{{ $t(opt.label) }} </span>
                      <span class="opv weight-900" v-html="opt.value" />
                    </div>
                    <div class="cl-light-gray">
                      <span class="opn">Ilość: <span class="weight-900">{{ prod.qty }}</span></span>
                    </div>
                    <div class="cl-light-gray" v-if="prod.totals">
                      <span class="cl-error" v-if="prod.totals.discount_amount">{{ prod.totals.row_total_incl_tax - prod.totals.discount_amount | price }} </span>
                      <span class="price-original h5 cl-black" v-if="prod.totals.discount_amount">
                        {{ prod.totals.row_total_incl_tax | price }}
                      </span>
                      <span v-if="!prod.totals.discount_amount" class="opn">Cena: <span class="weight-900">{{ prod.totals.row_total_incl_tax | price }}</span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-6 col-xs-12 thank-you-improvment">
              <h3 class="m0 py15">
                {{ $t('What we can improve?') }}
              </h3>
              <div>
                <p class="small-font m0">
                  {{ $t('Your feedback is important for us. Let us know what we could improve.') }}
                </p>
                <form @submit.prevent="sendFeedback">
                  <base-textarea
                    class="mb25 align-left pt10"
                    type="text"
                    name="body"
                    v-model="feedback"
                    :placeholder="$t('Type your opinion')"
                    :autofocus="true"
                  />
                  <button-outline class="thank-you-review" color="dark">
                    {{ $t('Give a feedback') }}
                  </button-outline>
                </form>
              </div>
            </div>
          </div>
          <div id="thank-you-extensions" />
          <template v-show="orderDetails && orderDetails.payment">
            <h3>
              <router-link v-show="isLogged" class="pointer" :to="localizedRoute('/my-account')" exact>
                {{ $t('Your Account') }}
              </router-link>
            </h3>
            <h3 v-show="!isLogged && orderDetails && orderDetails.payment" @click="goToAccount()" class="pointer">
              {{ $t('Your Account') }}
            </h3>
            <p class="mobile-p10" v-show="orderDetails && orderDetails.payment">
              {{ $t('You can log to your account using e-mail and password defined earlier. On your account you can ') }}
              <b>
                <router-link v-show="isLogged" class="pointer" :to="localizedRoute('/my-account')" exact>
                  {{ $t('edit your profile data,') }}
                </router-link>
              </b>
              <b v-show="!isLogged" @click="goToAccount()" class="pointer">{{ $t('edit your profile data,') }}</b>
              {{ $t('oraz') }} {{ $t(' check ') }}
              <b>
                <router-link v-show="isLogged" class="pointer" :to="localizedRoute('/my-account')" exact>
                  {{ $t('history of transactions') }}
                </router-link>
              </b>
              <b v-show="!isLogged" @click="goToAccount()" class="pointer">{{ $t('history of transactions') }}</b>
            </p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import Composite from '@vue-storefront/core/mixins/composite'
import BaseTextarea from 'theme/components/core/blocks/Form/BaseTextarea'
import ButtonOutline from 'theme/components/theme/ButtonOutline'
import fetch from 'isomorphic-fetch'
import VueOfflineMixin from 'vue-offline/mixin'
import { EmailForm } from '@vue-storefront/core/modules/mailer/components/EmailForm'
import { isServer } from '@vue-storefront/core/helpers'
import config from 'config'
import { registerModule } from '@vue-storefront/core/lib/modules'
import { MailerModule } from '@vue-storefront/core/modules/mailer'
import { mapGetters } from 'vuex'
import ProductImage from 'theme/components/core/ProductImage'
import { getNextProduct } from 'src/modules/google-tag-manager/hooks/afterRegistration'

export default {
  name: 'ThankYouPage',
  components: {
    ButtonOutline,
    BaseTextarea,
    ProductImage
  },
  beforeCreate () {
    registerModule(MailerModule)
  },
  props: {
    orderIncrementId: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: ''
    }
  },
  mixins: [Composite, VueOfflineMixin, EmailForm],
  data () {
    return {
      feedback: '',
      order_id: '',
      payment_result: '',
      order_increment_id: '',
      timer: null,
      wasLoad: false,
      cardTotals: '',
      cardProducts: '',
      orderDetails: '',
      responseDetails: ''
    }
  },
  computed: {
    ...mapGetters({
      isLogged: 'user/isLoggedIn'
    }),
    orderPlacedId () {
      return JSON.parse(localStorage.getItem('OrderPlacedId')) || []
    },
    isNotificationSupported () {
      if (isServer || !('Notification' in window)) return false
      return 'Notification' in window
    },
    isPermissionGranted () {
      if (isServer || !('Notification' in window)) return false
      return Notification.permission === 'granted'
    },
    checkoutPersonalEmailAddress () {
      return this.$store.state.checkout.personalDetails.emailAddress
    },
    mailerElements () {
      return config.mailer.contactAddress
    },
    isLoggedIn () {
      return this.$store.getters['user/isLoggedIn']
    },
    user () {
      return this.$store.state.user.current
    }
  },
  metaInfo () {
    return {
      title: 'Thank you page',
      meta: [
        {
          vmid: 'robots',
          name: 'robots',
          content: 'noindex, nofollow'
        }
      ]
    }
  },
  beforeMount () {
    if (this.status !== 'canceled') {
      this.getPaymentResult()
      this.startLoopPaymentResult()
    }
    this.$bus.$on('test-order-placed', this.getDataOnOrder)
  },
  beforeRouteEnter (to, from, next) {
    if (isServer) {
      next()
    } else {
      next(vm => {
        vm.$store.commit('ui/routeChanged', { to: to, from: from })
      })
    }
  },
  beforeDestroy () {
    localStorage.removeItem('OrderDetails')
    localStorage.removeItem('CardProducts')
    localStorage.removeItem('CardTotals')
    this.$bus.$off('test-order-placed', this.getDataOnOrder)
  },
  watch: {
    'OnlineOnly': 'onNetworkStatusCheck'
  },
  methods: {
    getDataOnOrder () {
      this.cardTotals = JSON.parse(localStorage.getItem('CardTotals')) || []
      this.cardProducts = JSON.parse(localStorage.getItem('CardProducts')) || []
      this.orderDetails = JSON.parse(localStorage.getItem('OrderDetails')) || []
      this.responseDetails = JSON.parse(localStorage.getItem('ResponseDetails')) || []
      if (this.responseDetails && Object.keys(this.responseDetails).length && this.orderDetails && !Object.keys(this.orderDetails).length) {
        this.orderDetails = this.setResponseDetailsAsOrderDetails()
      }
    },
    setResponseDetailsAsOrderDetails () {
      const responseDetails = this.responseDetails

      if (responseDetails.shippingAddress) {
        const orderData = {
          shipping: {
            firstName: responseDetails.shippingFirstName ? responseDetails.shippingFirstName : '',
            lastName: responseDetails.shippingLastName ? responseDetails.shippingLastName : '',
            streetAddress: responseDetails.shippingAddress.addressLine[0],
            apartmentNumber: responseDetails.shippingAddress.addressLine[1],
            zipCode: responseDetails.shippingAddress.postalCode,
            city: responseDetails.shippingAddress.city,
            company: responseDetails.shippingAddress.organization ? responseDetails.shippingAddress.organization : '',
            emailAddress: responseDetails.payerEmail,
            phoneNumber: responseDetails.shippingAddress.phone
          },
          payment: {
            firstName: responseDetails.shippingFirstName ? responseDetails.shippingFirstName : '',
            lastName: responseDetails.shippingLastName ? responseDetails.shippingLastName : '',
            streetAddress: responseDetails.shippingAddress.addressLine[0],
            apartmentNumber: responseDetails.shippingAddress.addressLine[1],
            flatNumber: '',
            zipCode: responseDetails.shippingAddress.postalCode,
            city: responseDetails.shippingAddress.city,
            company: responseDetails.shippingAddress.organization ? responseDetails.shippingAddress.organization : '',
            phoneNumber: responseDetails.shippingAddress.phone
          }
        }
        return orderData
      }
    },
    thankYouPagePush () {
      this.wasLoad = true
      let grand_total, tax, shipping, coupon
      if (this.cardTotals) {
        this.cardTotals.forEach((item) => {
          if (item.code === 'grand_total') {
            grand_total = item.value
          } else if (item.code === 'tax') {
            tax = item.value
          } else if (item.code === 'shipping') {
            shipping = item.value
          }
        })
      }
      let products = this.cardProducts && this.cardProducts.length ? this.cardProducts.map((item) => {
        return { childSku: item.sku, id: item.parentSku, name: item.name, price: item.totals.price_incl_tax, fbId: item.id, quantity: item.qty }
      }) : ''
      let nextProducts = this.cardProducts && this.cardProducts.length ? this.cardProducts.map((item, index) => {
        let tempProduct = getNextProduct({
          item,
          position: index
        }, this.$store)
        tempProduct.ga_fields['price'] = item.totals.price_incl_tax
        tempProduct['quantity'] = item.qty
        return tempProduct
      }) : ''
      window.dataLayer.push({
        event: 'uaevent',
        eventCategory: 'ecomm',
        eventAction: 'Purchase',
        ecomm: {
          purchase: {
            currency: 'PLN',
            actionField: {
              id: this.order_increment_id,
              affiliation: 'Online Store',
              revenue: grand_total,
              tax: tax,
              shipping: shipping
            },
            products
          }
        }
      });
      let orderDetaiWithId = { ...this.orderDetails, order_increment_id: this.orderIncrementId, order_id: this.order_id }
      Vue.prototype.$googleTagManager.purchase({
        products: nextProducts,
        cartTotals: this.cardTotals,
        orderDetails: orderDetaiWithId
      })
    },
    goToAccount () {
      if (this.currentUser) {
        this.$router.push(this.localizedRoute('/my-account'))
      } else {
        this.$bus.$emit('modal-show', 'modal-signup')
      }
    },
    async sendFeedback () {
      let splittedMailerElements = this.mailerElements.split(',')
      for (let i = 0; i < splittedMailerElements.length; i++) {
        await this.sendEmail({
          sourceAddress: this.orderDetails.shipping.emailAddress,
          targetAddress: splittedMailerElements[i],
          subject: `Feedback klienta do zamówienia #${this.order_increment_id}`,
          emailText: this.feedback
        },
        this.onSuccess,
        this.onFailure
        )
      }
    },
    onSuccess (message) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message,
        action1: { label: this.$t('OK') }
      })
      if (this.mailerElements.sendConfirmation) {
        this.sendEmail(
          {
            sourceAddress: this.mailerElements,
            targetAddress: this.orderDetails.shipping.emailAddress,
            subject: this.$t('Confirmation of receival'),
            emailText: this.$t(`Dear customer,\n\nWe have received your letter.\nThank you for your feedback!`),
            confirmation: true
          }
        )
      }
    },
    onFailure (message) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message,
        action1: { label: this.$t('OK') }
      })
    },
    requestNotificationPermission () {
      if (isServer) return false
      if ('Notification' in window && Notification.permission !== 'granted') {
        Notification.requestPermission()
      }
    },
    onNetworkStatusCheck (isOnline) {
      this.checkConnection(isOnline)
    },
    checkConnection (isOnline) {
      if (!isOnline) {
        this.notifyNoConnection()
      }
    },
    getPaymentResult () {
      fetch((config.payment_payu.endpoint.payment_information_by_increment_id).replace('{{ orderIncrementId }}', this.orderIncrementId), {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors'
      }).then(response => response.json())
        .then(data => {
          if (data.result) {
            this.order_id = data.result[0].order_id
            this.payment_result = data.result[0].result
            this.order_increment_id = data.result[0].order_increment_id
            if (data.result[0].result === 'completed') {
              this.getDataOnOrder()
              this.thankYouPagePush()
            }
            if (data.result[0].result === 'canceled') {
              this.$router.push({ path: this.$route.path, query: { status: 'canceled' } })
            }
          }
        })
    },
    startLoopPaymentResult () {
      this.$bus.$emit('notification-progress-start', this.$t('Processing payment...'))

      this.timer = setInterval(() => {
        if (this.payment_result === 'waiting') {
          this.getPaymentResult()
        } else {
          this.stopLoopPaymentResult()
        }
      }, 5000)
    },
    stopLoopPaymentResult () {
      clearInterval(this.timer)
      this.timer = null
      this.$bus.$emit('notification-progress-stop')
    }
  }
}
</script>

<style lang="scss">
  .shipping-data, .order {
    margin-right: 10px;
  }
  .thank-you-improvment {
    padding: 0 30px;
  }
  @media (max-width: 767px) {
    h3,
    .account-header {
      text-align: center;
    }
  }
  @media (max-width: 991px) {
    .account-header {
      padding-left: 5px;
      padding-right: 5px;
      margin: 10px
    }
    .mobile-p10 {
      padding: 10px;
    }
    .shipping-data, .order {
      margin-right: 0;
    }
  }
  .thank-you-improvment {
    padding: 0 20px 15px;

    textarea {
      border: 1px solid #000000;
      min-height: 100px;
      background: #fff;
    }
    .thank-you-review {
      margin-left: auto;
      margin-right: 0;
    }
  }
  .typ-summary {
    border: 1px solid #000000;
    background-color: #fff;
    .data-wrapper {
      display: flex;
      flex-direction: row;
    }
    .data-container {
      background-color: #F9F9F9;
      min-height: 150px;
      font-size: 0.8em;
    }
    .card-product {
      &:last-child {
        border-bottom: 1px solid #E1E1E1;
      }
      margin: 0px;
      background-color: transparent;
      .opn,
      .opv {
        font-size: 0.8em;
      }
      .card-product-name {
        max-width: 60%;
      }
    }
    @media (max-width: 767px) {
      border: none;
      .data-wrapper {
        flex-direction: column;
      }
      .card-product {
        margin: 0 5px;
        font-size: 0.9em;
      }
    }
  }
  .small-font {
    font-size: 0.9em
  }
</style>
